<template>
  <el-card id="log">
    <header>
      <h1>企业历史消费日志<span style="margin-left: 10px;font-size: 14px;">(不能查询当前月)</span></h1>

      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span style="width: 50px">企业ID</span>
            <el-input v-model="parentId" placeholder="请输入企业ID" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span style="width: 50px">选择月份</span>
            <el-date-picker v-model="day" type="month" placeholder="选择月" size="mini" value-format="yyyy-MM">
            </el-date-picker>
          </div>
          <div class="left-wrap">
            <span></span>
            <!-- <el-input v-model="form.remarks" placeholder="请输入备注" size="mini"></el-input> -->
            <el-radio v-model="typeCode" label="" style="margin: 0;" border size="mini">全部企业</el-radio>
            <el-radio v-model="typeCode" :label="1" border size="mini">套餐超出企业</el-radio>
          </div>
        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="mini"
            class="searchBtn">查找</el-button>
          <el-button class="reset" type="primary" icon="el-icon-refresh" @click="reset()" size="mini">重置</el-button>
          <!-- <el-button type="primary" icon="el-icon-folder-opened" @click="exportExcel" size="mini"
            class="reset">导出</el-button> -->
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
        <el-table-column prop="day" label="日期" align="center" width="140" fixed></el-table-column>

        <el-table-column :prop="v.prop" :label="v.label" align="center" v-for="v in newColumnsData" width="170"
          :key="v.key"></el-table-column>
        <el-table-column prop="realname" label="企业" align="center" width="300" fixed="right"></el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
          :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </main>
  </el-card>
</template>

<script>
// 导入所需的库和方法
// import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver';
import {
  monthtablelTable
} from "@/api/table";
export default {
  data() {
    return {
      typeCode: '',
      newColumnsData: [{
        key: 1,
        prop: 'salesman',
        label: '业务员',
      },
      {
        key: 2,
        prop: 'parent_id',
        label: '企业ID',
      },
      {
        key: 3,
        prop: 'add_money',
        label: '月租',
      },

      {
        key: 4,
        prop: 'money',
        label: '实际消费金额',
      },
      {
        key: 5,
        prop: 'money_wai',
        label: '实际消费金额(套餐外)',
      },
      {
        key: 6,
        prop: 'rate',
        label: '费率',
      },
      {
        key: 7,
        prop: 'time',
        label: '消费分钟',
      },
      {
        key: 8,
        prop: 'time_wai',
        label: '消费分钟(套餐外)',
      }],
      tableData: [],
      parentId: null,
      day: null,
      page: 1,
      size: 10,
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
    };
  },
  created() {
    this.$nextTick(() => {
      this.setDay()
      this.getList();
    })
  },
  methods: {
    // 导出表格数据为 Excel 文件
    exportExcel() {
      const headers = [
        { key: 'day', label: '日期' },
        { key: 'salesman', label: '业务员' },
        { key: 'parent_id', label: '企业ID' },
        { key: 'add_money', label: '月租' },
        { key: 'money', label: '实际消费金额' },
        { key: 'money_wai', label: '实际消费金额(套餐外)' },
        { key: 'rate', label: '费率' },
        { key: 'time', label: '消费分钟' },
        { key: 'time_wai', label: '消费分钟(套餐外)' },
        { key: 'realname', label: '企业' }
      ];

      // 获取 el-table 的数据
      const tableData = this.tableData;

      // 转换数据，替换属性名为对应的中文表头
      const transformedData = tableData.map(item => {
        const transformedItem = {};
        headers.forEach(header => {
          transformedItem[header.label] = item[header.key];
        });
        return transformedItem;
      });

      // 创建工作簿
      const workbook = XLSX.utils.book_new();

      // 创建工作表
      const worksheet = XLSX.utils.json_to_sheet(transformedData);

      // 设置列样式
      const columnStyles = headers.map(() => ({
        width: 15, // 设置默认宽度为15
        alignment: { horizontal: 'center' },
        font: { wrapText: true } // 设置自动换行
      }));
      worksheet['!cols'] = columnStyles;

      // 设置工作表默认样式，使所有单元格内容居中对齐
      const defaultStyle = { alignment: { horizontal: 'center' } };
      worksheet['!rows'] = [{ s: defaultStyle }];

      // 将工作表添加到工作簿中
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // 将工作簿转换为二进制数据流
      const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // 创建 Blob 对象
      const blob = new Blob([excelData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      // 保存文件
      saveAs(blob, '企业历史消费.xlsx');
    },

    // 设置时间未上个月
    setDay() {
      // 获取当前日期
      const currentDate = new Date();

      // 获取当前年份和月份
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      // 计算上个月的年份和月份
      let lastMonthYear;
      let lastMonth;
      if (currentMonth === 1) {
        // 如果当前月份是一月，获取昨年最后一个月份
        lastMonthYear = currentYear - 1;
        lastMonth = 12;
      } else {
        lastMonthYear = currentYear;
        lastMonth = currentMonth - 1;
      }

      this.day = `${lastMonthYear}-${lastMonth}`
    },
    // 搜索
    handleSearch() {
      this.getList();
    },
    // 重置
    reset() {
      this.setDay()
      this.parentId = null
      this.page = 1
      this.typeCode = ''
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 获取数据
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        day: this.day,
        parentId: this.parentId,
      };
      if (this.typeCode) params.typeCode = this.typeCode

      let res = monthtablelTable(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#log {
  header {
    h1 {
      font-weight: normal;
    }

    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            // width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>